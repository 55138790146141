// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>

import { Container } from 'react-bootstrap';
import { IProps } from '@interfaces/IProps';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import './MainLayout.css';
import AppHeader from '@components/header/AppHeader';
import { useUser } from '@contexts/UserProvider';

const MainLayout = ({ children, hideNavigation }: IProps) => {
    const user = useUser();

    return (
        <>
            {user.currentUser?.isLoggedIn ? (
                <AppHeader hideNavigation={hideNavigation} />
            ) : (
                <Container style={{ maxWidth: '1366px', padding: 0 }}>
                    <Header />
                </Container>
            )}
            <Container fluid className="main-layout-container">
                {children}
                {!user.currentUser?.isLoggedIn && <Footer />}
            </Container>
        </>
    );
};

export default MainLayout;
