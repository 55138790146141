import { Box, Stack, Typography } from '@mui/material';
import image404 from '@assets/images/image404.svg';
import ButtonComponent from '@components/button/Button';
import { useTranslation } from 'react-i18next';

const ErrorComponent = () => {
    const { t } = useTranslation();

    return (
        <Stack justifyContent={'center'} alignItems={'center'} gap={2} height={'100vh'}>
            <Box component="img" src={image404} width={325} height={180} />
            <Typography variant="h4" fontWeight={700} fontSize={30}>
                {t('not_found')}
            </Typography>
            <Typography variant="body2" maxWidth={460} textAlign="center" fontSize={16}>
                {t('not_found_message')}
            </Typography>
            <Box width={180} mt={2}>
                <ButtonComponent text={t('go_to_homepage')} onClick={() => (window.location.href = '/')} />
            </Box>
        </Stack>
    );
};

export default ErrorComponent;
