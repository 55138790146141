interface ApiErrorResponse {
    status: number;
    title: string;
    detail: string;
}

interface FetchOptions {
    method?: string;
    token?: string;
    data?: unknown;
    switchAccountHeader?: string;
    acceptHeader?: boolean;
}

let logoutFn: () => void;

export const initializeApi = (logoutCallback: () => void) => {
    logoutFn = logoutCallback;
};
const baseFetch = async <T>(url: string, options: FetchOptions = {}): Promise<T> => {
    const { method = 'GET', token, data } = options;

    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    };

    if (options.switchAccountHeader) {
        headers['x-teulu-profile-account-id'] = options.switchAccountHeader;
    }

    if (options.acceptHeader) {
        headers.Accept = 'image/png';
    }

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const fetchOptions: RequestInit = {
        method,
        headers,
    };

    if (data) {
        fetchOptions.body = JSON.stringify(data);
    }

    const response = await fetch(url, fetchOptions);

    if (response.status === 401) {
        logoutFn();
        throw new Error('Unauthorized');
    }

    if (response.status === 204) {
        return null as T;
    }

    if (!response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType?.includes('application/json')) {
            try {
                const errorBody = (await response.json()) as ApiErrorResponse;
                throw new Error(errorBody.detail || errorBody.title || 'An error occurred');
            } catch (e) {
                if (e instanceof Error) {
                    throw e;
                }
            }
        }
        throw new Error(`Error: ${response.statusText}`);
    }

    const contentType = response.headers.get('content-type');
    if (contentType?.includes('application/json')) {
        return (await response.json()) as T;
    }

    return null as T;
};

export const get = <T>(url: string, token?: string, switchAccountHeader?: string, acceptHeader?: boolean): Promise<T> => {
    return baseFetch<T>(url, { token, switchAccountHeader, acceptHeader });
};

export const post = <T, D>(url: string, data: D, token: string): Promise<T> => {
    return baseFetch<T>(url, { method: 'POST', data, token });
};

export const update = <T, D>(url: string, data: D, token?: string): Promise<T> => {
    return baseFetch<T>(url, { method: 'PUT', data, token });
};

export const deleteData = <T>(url: string, token?: string): Promise<T> => {
    return baseFetch<T>(url, { method: 'DELETE', token });
};
