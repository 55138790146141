import { createTheme } from '@mui/material/styles';
// import { blue } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        common: {
            white: '#ffffff',
        },
        primary: {
            main: '#836FFC',
            light: '#D7D7F0',
        },
        secondary: {
            main: '#F3F2FD',
            light: '#E8E7F4',
        },
        info: {
            main: '#E8B72D',
        },
        warning: { main: '#EAC613', dark: '#D8B60C', light: '#FDBD1A' },
        text: { primary: '#1E1903', secondary: '#37375C' },
        grey: { 50: '#F5F7FA', 100: '#94939C', 200: '#667085', 300: '#FAF9FB', 500: '#F4F4F4' },
        error: { main: '#d32f2f' },
    },
    shape: {
        borderRadius: 6,
    },
    typography: {
        fontFamily: 'Manrope, sans-serif',
    },
});

export default theme;
