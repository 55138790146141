import { Box, IconButton, Tooltip, Menu, MenuItem, PopoverVirtualElement, Typography, Stack, Divider } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'src/theme';
import useContactsStore from '@stores/useContactsStore';
import ButtonComponent from '@components/button/Button';
import { useInvitationCode } from '@hooks/useInvitationCode';
import { useUser } from '@contexts/UserProvider';
import NewNotificationIcon from '@assets/images/notification.svg';
import NoNotifications from '@assets/images/no-notifications.svg';

interface IMenu {
    button: React.ReactNode;
    anchorEl: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined;
    open: boolean;
    onClose: () => void;
    onOpen: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    buttonStyle?: React.CSSProperties;
    tooltip?: string;
    hasDivider?: boolean;
    width?: number;
    disabled?: boolean;
}

const NotificationMenu: React.FC<IMenu> = ({ button, anchorEl, open, onClose, onOpen, buttonStyle, tooltip, width, disabled }) => {
    const { t } = useTranslation();
    const { inviter, contacts, respondInvitation, getAccountProfiles } = useContactsStore();
    const contact = contacts.find((item) => item.id === inviter?.contactId) ?? inviter;
    // const contactAvatar = contact.id ? contactAvatars.find((avatar) => avatar.contactId === contact?.id);
    const contactAvatar = { avatarImage: null };
    const { invitationCode } = useInvitationCode();
    const user = useUser();
    const token = user.currentUser?.accessToken;

    const handleRespondInvitation = (respond: string) => {
        void respondInvitation(respond, invitationCode, token!);
        sessionStorage.removeItem('invitationCode');
        onClose();
        void getAccountProfiles(token!);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t('notification')}>
                <span>
                    <IconButton name={tooltip} onClick={onOpen} sx={buttonStyle} disabled={disabled} aria-label={tooltip}>
                        {invitationCode ? <img src={NewNotificationIcon} alt="Notification" /> : button}
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                sx={{
                    mt: '45px',
                    '& .MuiPaper-root': {
                        width: width,
                    },
                }}
                id="menu-user"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={onClose}
            >
                <Stack direction={'row'} p={3} maxWidth={400} minWidth={300} justifyContent={'space-between'}>
                    <Typography variant="h5" fontWeight={700} fontSize={20}>
                        {t('notification')}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        sx={{ backgroundColor: theme.palette.grey[50], color: theme.palette.grey[200], width: 40, height: 40, mt: -1 }}
                        onClick={() => onClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Divider />
                {contact ? (
                    <MenuItem
                        sx={{ backgroundColor: theme.palette.grey[50], padding: 3, mb: -1 }}
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Stack direction={'row'}>
                            <Stack>
                                {contactAvatar?.avatarImage ? (
                                    <img
                                        style={{ borderRadius: 100 }}
                                        src={contactAvatar.avatarImage ? contactAvatar.avatarImage : ''}
                                        alt="avatar"
                                        width={48}
                                        height={48}
                                    />
                                ) : (
                                    <Box
                                        height={48}
                                        width={48}
                                        borderRadius={100}
                                        sx={{ backgroundColor: theme.palette.grey[400] }}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        {contact.firstName ? contact.firstName[0] : ''}
                                        {contact.lastName ? contact.lastName[0] : ''}
                                    </Box>
                                )}
                            </Stack>
                            <Stack ml={2} flexWrap={'wrap'}>
                                <Typography variant="body1" fontWeight={600}>{`${contact.firstName} ${contact.lastName}`}</Typography>
                                <Typography
                                    variant="body1"
                                    mt={1}
                                    sx={{
                                        width: 300,
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word',
                                        overflowWrap: 'break-word',
                                    }}
                                >
                                    <Trans i18nKey="notification_invite_description" />
                                </Typography>
                                <Stack direction={'row'} gap={2} mt={2}>
                                    <ButtonComponent
                                        onClick={() => handleRespondInvitation('DECLINED')}
                                        text={t('decline')}
                                        variant="outlined"
                                        styles={{ height: 32, width: 82, fontSize: 14 }}
                                    />
                                    <ButtonComponent
                                        onClick={() => handleRespondInvitation('ACCEPTED')}
                                        text={t('accept')}
                                        styles={{ height: 32, width: 82, fontSize: 14 }}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </MenuItem>
                ) : (
                    <Stack justifyContent={'center'} alignItems={'center'} p={6}>
                        <Box component={'img'} src={NoNotifications} width={132} height={132} />
                        <Typography maxWidth={317} variant="h5" fontWeight={700} fontSize={20} mt={2}>
                            {t('no_notification_title')}
                        </Typography>
                        <Typography textAlign={'center'} maxWidth={270} variant="body2" fontWeight={400} fontSize={16} mt={2}>
                            {t('no_notification_subtitle')}
                        </Typography>
                    </Stack>
                )}
            </Menu>
        </Box>
    );
};

export default NotificationMenu;
