import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { get as getData, post as postData, update as updateData, deleteData } from '../common/api';
export interface Contact {
    id?: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email: string;
    phone: string;
    contactType: string;
    comment: string;
    hasAvatar?: boolean;
    invitationStatus?: 'PENDING' | 'ACCEPTED' | undefined;
    address: {
        street: string;
        streetNumber: string;
        postCode: string;
        city: string;
        country: string;
    };
}

interface AvatarUrl {
    url: string;
    expiresAt: string;
}
interface ContactAvatar {
    contactId: string;
    avatarUpload?: AvatarUrl;
    avatar?: AvatarUrl;
    avatarImage?: string;
    isLoading: boolean;
    error: string | null;
}

interface InviterResponse {
    contactId: string;
    firstName: string;
    lastName: string;
}

interface Profile {
    accountId: string;
    firstName: string;
    lastName: string;
}

interface AddContactResponse {
    id: string;
    _links: {
        uploadLink: {
            expirestAt: string;
            url: string;
        };
    };
}

interface ContactsState {
    contacts: Contact[];
    selectedContact: Contact | null;
    editContact: Contact | null;
    isLoading: boolean;
    error: string | null;
    contactAvatars: ContactAvatar[];
    inviter: InviterResponse | null;
    accountProfiles: Profile[];
    activeAccount: string;
    isSharedAccount: boolean;

    fetchContacts: (token: string) => Promise<void>;
    addContact: (contact: Omit<Contact, 'id'>, token: string) => Promise<AddContactResponse>;
    updateContact: (id: string, contact: Partial<Contact>, token: string) => Promise<void>;
    deleteContact: (id: string, token: string) => Promise<void>;
    setSelectedContact: (contact: Contact | null) => void;
    setEditContact: (contact: Contact | null) => void;
    sendInvitation: (id: string, message: string, token: string) => Promise<void>;

    initializeContactAvatar: (contactId: string) => void;
    loadContactAvatar: (contactId: string, token: string) => Promise<void>;
    getContactAvatarUploadUrl: (id: string, token: string) => Promise<void>;
    getContactAvatarUrl: (id: string, token: string) => Promise<void>;
    uploadContactAvatar: (url: string, image: File) => Promise<void>;
    removeContactAvatarImageFromStore: (id: string) => void;
    addContactAvatarImageToStore: (id: string, image: string) => void;

    getInviterByInvitationCode: (invitationCode: string, token: string) => Promise<InviterResponse>;
    setInviter: (inviter: InviterResponse) => void;
    respondInvitation: (respond: string, invitationCode: string, token: string) => Promise<void>;
    getAccountProfiles: (token: string) => Promise<void>;
    setActiveAccount: (id: string) => void;
    setIsSharedAccount: (isShared: boolean) => void;
}

export const useContactsStore = create<ContactsState>()(
    devtools((set, get) => ({
        contacts: [],
        selectedContact: null,
        editContact: null,
        isLoading: false,
        error: null,
        contactAvatars: [],
        inviter: null,
        accountProfiles: [],
        activeAccount: '',
        isSharedAccount: false,

        fetchContacts: async (token) => {
            set({ isLoading: true, error: null });
            try {
                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts`;
                const data: Contact[] = await getData(url, token);
                set({ contacts: data, isLoading: false });
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to fetch contacts',
                    isLoading: false,
                });
            }
        },

        addContact: async (contactData, token) => {
            set({ isLoading: true, error: null });
            try {
                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts`;
                const newContact: AddContactResponse = await postData(url, contactData, token);
                const newContactWithId = { ...contactData, ...newContact };
                set((state) => ({
                    contacts: [...state.contacts, newContactWithId],
                    isLoading: false,
                }));

                return newContact;
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to add contact',
                    isLoading: false,
                });
                return Promise.reject(new Error(error instanceof Error ? error.message : 'Failed to add contact'));
            }
        },

        updateContact: async (id, updatedContact, token) => {
            set({ isLoading: true, error: null });
            try {
                set((state) => ({
                    contacts: state.contacts.map((contact) => (contact.id === id ? { ...contact, ...updatedContact } : contact)),
                    selectedContact: null,
                    isLoading: false,
                }));

                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}`;
                await updateData(url, updatedContact, token);
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to update contact',
                    isLoading: false,
                });
            }
        },

        deleteContact: async (id, token) => {
            set({ isLoading: true, error: null });
            try {
                set((state) => ({
                    contacts: state.contacts.filter((contact) => contact.id !== id),
                    selectedContact: null,
                    isLoading: false,
                }));

                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}`;
                await deleteData(url, token);
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to delete contact',
                    isLoading: false,
                });
            }
        },

        sendInvitation: async (id, message, token) => {
            set({ isLoading: true, error: null });
            const sendMessage = { message: message };
            try {
                const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}/invite`;
                await postData(url, sendMessage, token);
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to send invitation',
                    isLoading: false,
                });
            }
        },

        setSelectedContact: (contact) => {
            set({ selectedContact: contact });
        },

        setEditContact: (contact) => {
            set({ editContact: contact });
        },
        initializeContactAvatar: (contactId: string) => {
            set((state) => ({
                contactAvatars: [
                    ...state.contactAvatars.filter((avatar) => avatar.contactId !== contactId),
                    {
                        contactId,
                        isLoading: false,
                        error: null,
                    },
                ],
            }));
        },

        loadContactAvatar: async (contactId: string, token: string) => {
            const state = get();
            let avatar = state.contactAvatars.find((a) => a.contactId === contactId);

            if (!avatar) {
                state.initializeContactAvatar(contactId);
                avatar = state.contactAvatars.find((a) => a.contactId === contactId);
            }

            if (avatar?.isLoading || avatar?.avatarImage) {
                return;
            }

            set((state) => ({
                contactAvatars: state.contactAvatars.map((a) => (a.contactId === contactId ? { ...a, isLoading: true, error: null } : a)),
            }));

            try {
                const uploadUrlResponse: AvatarUrl = await getData(
                    `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${contactId}/avatar-upload-url`,
                    token,
                );

                set((state) => ({
                    contactAvatars: state.contactAvatars.map((a) => (a.contactId === contactId ? { ...a, avatarUpload: uploadUrlResponse } : a)),
                }));

                const contact = state.contacts.find((c) => c.id === contactId);
                if (contact?.hasAvatar) {
                    const avatarUrlResponse: AvatarUrl = await getData(`${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${contactId}/avatar-url`, token);

                    set((state) => ({
                        contactAvatars: state.contactAvatars.map((a) => (a.contactId === contactId ? { ...a, avatar: avatarUrlResponse } : a)),
                    }));

                    const imageResponse = await fetch(avatarUrlResponse.url, {
                        cache: 'reload',
                        headers: {
                            'Cache-Control': 'no-cache, no-store, must-revalidate',
                            Pragma: 'no-cache',
                            Expires: '0',
                        },
                    });

                    if (imageResponse.status === 404) {
                        throw new Error('Avatar image not found');
                    }

                    const imageBlob = await imageResponse.blob();
                    const avatarImage = URL.createObjectURL(imageBlob);

                    set((state) => ({
                        contactAvatars: state.contactAvatars.map((a) => (a.contactId === contactId ? { ...a, avatarImage, isLoading: false, error: null } : a)),
                    }));
                } else {
                    set((state) => ({
                        contactAvatars: state.contactAvatars.map((a) => (a.contactId === contactId ? { ...a, isLoading: false, error: null } : a)),
                    }));
                }
            } catch (error) {
                set((state) => ({
                    contactAvatars: state.contactAvatars.map((a) =>
                        a.contactId === contactId
                            ? {
                                  ...a,
                                  isLoading: false,
                                  error: error instanceof Error ? error.message : 'Failed to load avatar',
                              }
                            : a,
                    ),
                }));
            }
        },
        getContactAvatarUploadUrl: async (id: string, token: string) => {
            await get().loadContactAvatar(id, token);
        },

        getContactAvatarUrl: async (id: string, token: string) => {
            await get().loadContactAvatar(id, token);
        },
        uploadContactAvatar: async (url, image) => {
            try {
                await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'image/*',
                    },
                    body: image,
                });
            } catch (error) {
                console.error('Error during upload:', error);
            }
        },

        removeContactAvatarImageFromStore: (id) => {
            set((state) => {
                const newState = {
                    contactAvatars: state.contactAvatars.map((avatar) =>
                        avatar.contactId === id
                            ? {
                                  ...avatar,
                                  avatarImage: '',
                                  timestamp: Date.now(),
                              }
                            : avatar,
                    ),
                };
                return newState;
            });
        },

        addContactAvatarImageToStore: (id: string, image: string) => {
            set((state) => {
                const contactExists = state.contactAvatars.some((avatar) => avatar.contactId === id);

                if (contactExists) {
                    return {
                        contactAvatars: state.contactAvatars.map((avatar) =>
                            avatar.contactId === id
                                ? {
                                      ...avatar,
                                      avatarImage: image,
                                      isLoading: false,
                                      error: null,
                                  }
                                : avatar,
                        ),
                    };
                }

                return {
                    contactAvatars: [
                        ...state.contactAvatars,
                        {
                            contactId: id,
                            avatarImage: image,
                            isLoading: false,
                            error: null,
                        },
                    ],
                };
            });
        },

        getInviterByInvitationCode: async (invitationCode, token) => {
            const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/get-inviter-by-invitation-code?invitation_code=${invitationCode}`;
            const response = await getData<InviterResponse>(url, token);

            return response;
        },

        setInviter: (inviter) => {
            set({ inviter: inviter });
        },

        respondInvitation: async (respond, invitationCode, token) => {
            const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/respond-invitation?invitation_code=${invitationCode}`;
            await postData(url, { response: respond }, token);
            return;
        },

        getAccountProfiles: async (token) => {
            try {
                const url = `${process.env.REACT_APP_API_URL}/accounts/v1/accounts/profiles`;
                const data: Profile[] = await getData(url, token);
                set({ accountProfiles: data, isLoading: false });
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to fetch profiles',
                    isLoading: false,
                });
            }
        },

        setActiveAccount: (id: string) => {
            set({ activeAccount: id });
        },

        setIsSharedAccount: (isShared: boolean) => {
            set({ isSharedAccount: isShared });
        },
    })),
);

export default useContactsStore;
