import { Box, IconButton, Tooltip, Menu, MenuItem, PopoverVirtualElement, Typography, Stack, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from 'src/theme';
import useContactsStore from '@stores/useContactsStore';
import { useUser } from '@contexts/UserProvider';
import { useUserSettingStore } from '@stores/useUserSettingsStore';
import ActiveIcon from '@assets/images/active.svg';

interface IMenu {
    button: React.ReactNode;
    anchorEl: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined;
    open: boolean;
    onClose: () => void;
    onOpen: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    buttonStyle?: React.CSSProperties;
    tooltip?: string;
    hasDivider?: boolean;
    width?: number;
    disabled?: boolean;
}

const ProfileMenu: React.FC<IMenu> = ({ button, anchorEl, open, onClose, onOpen, buttonStyle, tooltip, width, disabled }) => {
    const { t } = useTranslation();
    const { accountProfiles, activeAccount, setActiveAccount, setIsSharedAccount } = useContactsStore();
    const { salutation } = useUserSettingStore();
    const user = useUser();
    const handleLogout = () => {
        user?.logout();
    };

    const handleSwitchAccount = (accountId: string | undefined) => {
        console.log('Switching account to: ', accountId);
        setActiveAccount(accountId!);

        if (user.currentUser?.accountId !== accountId) {
            setIsSharedAccount(true);
        } else if (user.currentUser?.accountId === accountId) {
            setIsSharedAccount(false);
        }
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t('profile')}>
                <span>
                    <IconButton name={tooltip} onClick={onOpen} sx={buttonStyle} disabled={disabled} aria-label={tooltip}>
                        {button}
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                sx={{
                    mt: '45px',
                    '& .MuiPaper-root': {
                        width: width,
                    },
                }}
                id="menu-user"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={onClose}
            >
                <Stack direction={'column'} maxWidth={400} minWidth={300} justifyContent={'space-between'}>
                    <Stack direction={'row'} mb={'5px'}>
                        <Box
                            ml={3}
                            my={1}
                            height={40}
                            width={40}
                            borderRadius={100}
                            sx={{ backgroundColor: theme.palette.grey[400] }}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            color={'common.white'}
                        >
                            {salutation.firstName ? salutation.firstName[0] : ''}
                            {salutation.lastName ? salutation.lastName[0] : ''}
                        </Box>
                        <Stack justifyContent={'center'}>
                            <Typography fontWeight={500} fontSize={14} px={2}>
                                {tooltip}
                            </Typography>
                            <Typography variant="body2" fontWeight={400} fontSize={14} px={2} color={theme.palette.grey[200]}>
                                {salutation.email}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack>
                        <Typography px={3} py={1} fontSize={14} fontWeight={400} height={40}>
                            {t('switch_profile')}
                        </Typography>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            sx={{ backgroundColor: activeAccount === user.currentUser?.accountId ? theme.palette.grey[50] : 'inherit' }}
                        >
                            <MenuItem
                                sx={{ py: 2, fontSize: 14, fontWeight: 400, height: 44, width: '100%' }}
                                onClick={() => {
                                    onClose();
                                    handleSwitchAccount(user.currentUser?.accountId);
                                }}
                            >
                                <Box
                                    ml={2}
                                    mr={2}
                                    height={24}
                                    width={24}
                                    fontSize={12}
                                    borderRadius={100}
                                    sx={{ backgroundColor: theme.palette.grey[400] }}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    color={'common.white'}
                                >
                                    {salutation.firstName ? salutation.firstName[0] : ''}
                                    {salutation.lastName ? salutation.lastName[0] : ''}
                                </Box>
                                {tooltip}
                                {activeAccount === user.currentUser?.accountId && <Box component={'img'} src={ActiveIcon} ml={8} />}
                            </MenuItem>
                        </Stack>

                        {accountProfiles.map((profile) => (
                            <Stack
                                key={profile.accountId}
                                direction={'row'}
                                alignItems={'center'}
                                sx={{ backgroundColor: activeAccount === profile.accountId ? theme.palette.grey[50] : 'inherit' }}
                            >
                                <MenuItem
                                    sx={{ py: 2, fontSize: 14, fontWeight: 400, height: 44, width: '100%' }}
                                    onClick={() => {
                                        onClose();
                                        handleSwitchAccount(profile.accountId);
                                    }}
                                >
                                    <Box
                                        ml={2}
                                        mr={2}
                                        height={24}
                                        width={24}
                                        fontSize={12}
                                        borderRadius={100}
                                        sx={{ backgroundColor: theme.palette.grey[400] }}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        color={'common.white'}
                                    >
                                        {profile.firstName ? profile.firstName[0] : ''}
                                        {profile.lastName ? profile.lastName[0] : ''}
                                    </Box>
                                    {`${profile.firstName} ${profile.lastName}`}
                                    {activeAccount === profile.accountId && <Box component={'img'} src={ActiveIcon} ml={8} />}
                                </MenuItem>
                            </Stack>
                        ))}
                        <Stack mt={'-1px'}>
                            <Divider />
                        </Stack>
                        <MenuItem
                            sx={{ px: 3, mb: '-7px', py: 2, fontSize: 14, fontWeight: 400, height: 44 }}
                            onClick={(e) => {
                                e.preventDefault();
                                handleLogout();
                            }}
                        >
                            {t('common.logout_button_label')}
                        </MenuItem>
                    </Stack>
                </Stack>
            </Menu>
        </Box>
    );
};

export default ProfileMenu;
