import { useState, useEffect } from 'react';

export const useInvitationCode = () => {
    const [invitationCode, setInvitationCode] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('invitationCode');

        if (code) {
            sessionStorage.setItem('invitationCode', code);
            setInvitationCode(code);
        } else {
            const storedCode = sessionStorage.getItem('invitationCode');
            if (storedCode) {
                setInvitationCode(storedCode);
            }
        }
    }, []);

    const clearInvitationCode = () => {
        sessionStorage.removeItem('invitationCode');
        setInvitationCode('');
    };

    return { invitationCode, clearInvitationCode };
};
