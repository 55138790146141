import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { get as getData, update as updateData } from '../common/api';
interface Salutation {
    firstName: string;
    lastName: string;
    displayName: string;
    termsAndConditionsAccepted: boolean | undefined;
    email: string;
}

export interface UserSettingsDetails {
    gender: string;
    martialStatus: string;
    isHoldingPower: boolean;
    directivesIntro: boolean;
    nursingIntro: boolean;
    vehiclesIntro: boolean;
    hasCar: boolean;
    hasLicense: boolean;
    directivesAppInfo: boolean;
    notRelevantSteps: string[];
    doneSteps: string[];
    userTotalSteps: number;
    doneWizards: string[];
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    dateOfBirth: string;
    placeOfBirth: string;
    nationality: string;
    religion: string;
    street: string;
    streetNumber: string;
    postcode: string;
    city: string;
    country: string;
    language: string;
    startingView: string;
}

interface UserSettingsStore {
    salutation: Salutation;
    tab: string;
    userSettingsDetails: UserSettingsDetails;
    isLoading: boolean;
    error: string | null;
    setTab: (tab: string) => void;
    updateSalutation: (data: Partial<Salutation>) => void;
    fetchUserSettings: (token: string) => Promise<void>;
    updateGender: (gender: string) => void;
    updateMartialStatus: (status: string) => void;
    updateUserSettingsField: <T extends keyof UserSettingsDetails>(field: T, value: UserSettingsDetails[T]) => void;
    addNotRelevantStep: (step: string) => void;
    removeNotRelevantStep: (step: string) => void;
    addDoneStep: (step: string) => void;
    removeDoneStep: (step: string) => void;
    fetchUserSettingsDetails: (token: string) => Promise<void>;
    saveUserSettingsDetails: (token: string) => Promise<void>;
    setUserTotalSteps: (length: number) => void;
    setWizardDone: (step: string) => void;
}

export const useUserSettingStore = create<UserSettingsStore>()(
    devtools((set, get) => ({
        salutation: {
            firstName: '',
            lastName: '',
            displayName: '',
            termsAndConditionsAccepted: false,
            email: '',
        },
        tab: 'Wizards',
        userSettingsDetails: {
            gender: 'Choose',
            martialStatus: 'Choose',
            isHoldingPower: false,
            directivesIntro: false,
            nursingIntro: false,
            vehiclesIntro: false,
            hasCar: false,
            hasLicense: false,
            directivesAppInfo: false,
            notRelevantSteps: [],
            doneSteps: [],
            userTotalSteps: 0,
            doneWizards: [],
            firstName: '',
            lastName: '',
            displayName: '',
            email: '',
            dateOfBirth: '',
            placeOfBirth: '',
            nationality: '',
            religion: '',
            street: '',
            streetNumber: '',
            postcode: '',
            city: '',
            country: '',
            language: '',
            startingView: '',
        },
        isLoading: false,
        error: null,

        setTab: (newTab) => {
            set({ tab: newTab });
        },

        updateSalutation: (data) =>
            set((state) => ({
                salutation: {
                    ...state.salutation,
                    ...data,
                },
            })),

        fetchUserSettings: async (token: string) => {
            const url = `${process.env.REACT_APP_API_URL}/accounts/v1/accounts`;

            try {
                const data: Salutation = await getData(url, token);
                set((state) => ({
                    ...state,
                    salutation: data,
                }));
            } catch (error) {
                console.log(error);
            }
        },

        updateGender: (gender) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    gender,
                },
            })),

        updateMartialStatus: (status) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    martialStatus: status,
                },
            })),

        updateUserSettingsField: (field, value) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    [field]: value,
                },
            })),

        addNotRelevantStep: (step) =>
            set((state) => {
                const currentDoneSteps = state.userSettingsDetails?.notRelevantSteps || [];
                return {
                    userSettingsDetails: {
                        ...state.userSettingsDetails,
                        notRelevantSteps: currentDoneSteps.includes(step) ? currentDoneSteps : [...currentDoneSteps, step],
                    },
                };
            }),

        removeNotRelevantStep: (step) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    notRelevantSteps: state.userSettingsDetails.notRelevantSteps.filter((s) => s !== step),
                },
            })),

        addDoneStep: (step: string) =>
            set((state) => {
                const currentDoneSteps = state.userSettingsDetails?.doneSteps || [];
                return {
                    userSettingsDetails: {
                        ...state.userSettingsDetails,
                        doneSteps: currentDoneSteps.includes(step) ? currentDoneSteps : [...currentDoneSteps, step],
                    },
                };
            }),

        removeDoneStep: (step) =>
            set((state) => ({
                ...state,
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    doneSteps: state.userSettingsDetails?.doneSteps?.filter((s) => s !== step),
                },
            })),

        fetchUserSettingsDetails: async (token) => {
            try {
                set({ isLoading: true, error: null });
                const url = `${process.env.REACT_APP_API_URL}/accounts/v1/accounts/settings`;
                const data = await getData(url, token);
                set({
                    userSettingsDetails: data as UserSettingsDetails,
                    isLoading: false,
                });
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to fetch user settings',
                    isLoading: false,
                });
            }
        },

        saveUserSettingsDetails: async (token) => {
            try {
                set({ isLoading: true, error: null });
                const url = `${process.env.REACT_APP_API_URL}/accounts/v1/accounts/settings`;
                await updateData(url, get().userSettingsDetails, token);
                set({
                    isLoading: false,
                });
            } catch (error) {
                set({
                    error: error instanceof Error ? error.message : 'Failed to save user settings',
                    isLoading: false,
                });
            }
        },

        setUserTotalSteps: (steps) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    userTotalSteps: steps,
                },
            })),

        setWizardDone: (step) =>
            set((state) => {
                const currentDoneWizards = state.userSettingsDetails?.doneWizards || [];
                return {
                    userSettingsDetails: {
                        ...state.userSettingsDetails,
                        doneWizards: currentDoneWizards.includes(step) ? currentDoneWizards : [...currentDoneWizards, step],
                    },
                };
            }),
    })),
);
