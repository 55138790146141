// // © 2024 finBalance - Ingo.Brenckmann@finbalance.de
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/index';
import './App.css';
import initI18n from './i18n';

import UserProvider from '@contexts/UserProvider';
import NotificationProvider from '@contexts/NotificationProvider';

import PublicRoute from '@components/publicPrivateRoute/PublicRoute';
import PrivateRoute from '@components/publicPrivateRoute/PrivateRoute';

import WelcomePage from './pages/WelcomePage/WelcomePage';
import Loading from '@components/loading/Loading';
import ErrorBoundary from '@components/errorBoundary';
import AuthenticatedLayout from '@components/layout/AuthenticatedLayout';
import { useInvitationCode } from '@hooks/useInvitationCode';
import ErrorComponent from './error/Error';

// import PdfContent from '@components/pdfContent/PdfContent';
// import { useWizardStore } from '@stores/useWizzardStore';

async function setupApp() {
    await initI18n();
}

function App() {
    const { invitationCode } = useInvitationCode();

    //const { pdfContent } = useWizardStore();

    useEffect(() => {
        if (invitationCode) {
            console.log('Using invitation code:', invitationCode);
        }
    }, [invitationCode]);

    useEffect(() => {
        void setupApp();
    }, []);

    return (
        <>
            {/* {pdfContent ? (
                <PdfContent printContent={pdfContent} />
            ) : ( */}
            <div className="App">
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <NotificationProvider>
                            <UserProvider>
                                <ErrorBoundary fallback={<ErrorComponent />}>
                                    <Suspense fallback={<Loading open={false} />}>
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={
                                                    <PublicRoute>
                                                        <WelcomePage />
                                                    </PublicRoute>
                                                }
                                            />
                                            <Route
                                                path="*"
                                                element={
                                                    <PrivateRoute>
                                                        <AuthenticatedLayout />
                                                    </PrivateRoute>
                                                }
                                            />
                                        </Routes>
                                    </Suspense>
                                </ErrorBoundary>
                            </UserProvider>
                        </NotificationProvider>
                    </ThemeProvider>
                </BrowserRouter>
            </div>
            {/* )} */}
        </>
    );
}

export default App;
